/*
        PortfolioCover styles
*/

.portfolio-cover {
  background-color: var(--background-light-mode);
}
.portfolio-cover-section {
  background-color: var(--background-light-mode);
}
.pc-head {
  padding: 10px;
  font-size: 35px;
  font-family: Helvetica;
  font-weight: 600;
  text-align: center;
  letter-spacing: 1px;
  color: #bd7500;
}

.pc-bar {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  align-items: center;
}
.pc-bar div {
  display: flex;
  padding: 0 30px;
  background: #e1b023;
  border-radius: 15px;
  align-items: center;
}
.pc-bar div p {
  padding: 15px;
  background: #003800;
  border-radius: 15px;
  color: #e1b023;
}
.pc-bar div ul {
  display: flex;
  gap: 70px;
}
.pc-bar div ul li {
  list-style: none;
  font-size: 15px;
  font-weight: 500;
  color: #002300;
}
.pc-laptop {
  padding: 20px 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pc-laptop p img {
  width: 100%;
  height: 100%;
}
.pc-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.pc-footer-text {
  font-size: 18px;
  text-align: center;
}
.vn {
  position: relative;
}
.pc-ball {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  z-index: -1;
  position: absolute;
  top: -5px;
  left: -10px;
  background: rgb(191 191 191);
}

@media screen and (min-width: 350px) and (max-width: 600px) {
  .pc-bar div {
    padding: 0 5px; /* Further reduce padding on smaller screens */
    margin: 2px; /* Reduce margin between items */
    overflow: hidden;
   
  }
  .pc-head {
    margin-top: 50px;
  }

  .pc-bar div p {
    font-size: 12px; /* Decrease font size on smaller screens */
  }

  .pc-bar div ul {
    display: grid; /* Use grid layout */
    grid-template-columns: 1fr 1fr 1fr; /* Three columns */
    gap: 1rem; /* Add gap between grid items */
  }
  
  
  .pc-bar div ul li {
    font-size: 10px; /* Decrease font size on smaller screens */
    
  }

  .pc-laptop {
    padding: 10px; /* Add padding for better spacing on smaller screens */
  }
}
@media  screen and (min-width: 350px) and (max-width: 600px){
  .portfolio-cover-section {
  }
  .pc-head {
    padding: 1px;
  }
  .pc-head {
    font-size: 15px;
  }
  .pc-bar div {
    padding: 0 10px;
    border-radius: 10px;
  }
  .pc-bar div p {
    padding: 5px;
    margin: 10px;
    font-size: 8px;
    border-radius: 5px;
  }
  .pc-laptop {
    padding: 10px 100px;
  }
  .pc-bar div ul {
    gap: 15px;
    padding-left: 0;
    display: flex;
  }
  .pc-bar div ul li {
    font-size: 8px;
  }
  .pc-text {
    width: 48%;
  }
  .pct-head {
    font-size: 9px;
  }
  .pc-link {
    font-size: 14px;
  }
  .pc-images {
    width: 50%;
    overflow: hidden;
  }
  .pc-images p:nth-child(1) {
    left: 0;
    width: 95%;
    height: 8rem;
    top: 0;
  }
  .pc-images p:nth-child(2) {
    width: 95%;
    height: 8rem;
    left: -30px;
  }
  .pc-images p:nth-child(3) {
    width: 95%;
    height: 8rem;
    left: -60px;
  }
}