
/*


contact us page styling



 */

.contact-us-page-socials {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  .contact-us-page-socials-div {
    display: flex;
    gap: 15px;

    .contact-us-social-icons {
      text-decoration: none;

      .social-icons {
        font-size: 40px;
        color: #e1b023;
      }
    }
  }
}
.contact-us-page-map-section {
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.contact-us-page-contact-details-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  .contact-us-page-contact-details-section-div {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    div {
      width: 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .contact-us-page-icon-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        box-shadow: 2px 2px 2px #e1b023;
        text-decoration: none;
        color: #e1b023;
        font-size: 25px;
      }
      h5 {
        color: #252b42;
        text-align: center;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.2px;
      }
    }
  }
}
.contact-us-page-form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 100px;
  form {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 4px 4px 5px rgb(117, 114, 114);
    padding: 0px 20px;
    div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding: 30px 10px;
      width: 100%;
      h1 {
        color: #252b42;
        width: 100%;
        text-align: center;

        font-family: "Montserrat";
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0.1px;
      }
      input {
        display: flex;

        padding: 20px 20px;
        margin: 15px 0;
        align-items: center;
        align-self: stretch;
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        background: #f5f5f5;
      }
      .contact-us-page-form-your-message {
        height: 147px;
      }
      button {
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 10px 35px;
        border-radius: 10px;
        background: #f3c720;
        border: none;
        color: #fff;
        font-family: "Montserrat";
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0.2px;
      }
    }
  }
}

@media screen and (min-width: 350px) and (max-width: 600px){

  /*


  contact us page styling



   */

  .contact-us-page-socials {

    padding: 25px 0;
    .contact-us-page-socials-div {
      display: flex;
      gap: 15px;

      .contact-us-social-icons {
        text-decoration: none;

        .social-icons {
          font-size: 20px;
        }
      }
    }
  }

  .contact-us-page-contact-details-section {

    padding: 30px;
    .contact-us-page-contact-details-section-div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      div {
        .contact-us-page-icon-circle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 25px;
          height: 25px;
          color: #e1b023;
          font-size: 15px;
        }
        h5 {
          font-size: 8px;

        }
      }
    }
  }
  .contact-us-page-form-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    form {
      width: 100%;
      border-radius: 10px;
      padding: 0px 10px;
      div {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 10px 10px;
        width: 100%;
        h1 {

          font-family: "Montserrat";
          font-size: 12px;
        }
        input {
          display: flex;

          padding: 10px 20px;
          margin: 15px 0;
          border-radius: 5px;
          font-size: 9px;

        }
        .contact-us-page-form-your-message {
          height: 107px;
        }
        button {
          flex-direction: column;
          align-items: center;
          gap: 10px;
          padding: 8px 25px;
          border-radius: 5px;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 0.2px;
        }
      }
    }
  }

  .about-page-header {
    background: #f7fff5;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .about-page-header-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p{
      width: 80%;
      font-size: 12px;
    }
  }
  .about-page-header-div h1 {

    font-size: 20px;

    margin: 10px;
  }
  .about-page-header-div h2 {
    color: #0b6a0b;
    font-family: "DM Sans", "sans-serif";
    font-size: 14px;
  }
}