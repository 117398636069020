
.banner-section.light {
  background-color: #f6f6f6;
  color: var(--background-light-mode);
}

.banner-section.dark {
  background: var(--dark-mode-dark-text);
  color: #fff;
}

.index-page-banner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.index-page-banner-container.dark {
  color: #000;
}

.index-page-banner-container.light {
  color: #fff;
}

.banner-image.dark {
  background: var(--dark-mode-dark-text);
}

.banner-image.light {
  background-color: var(--background-light-mode);
}

.index-page-banner-container img {
  margin-top: 20px;
  width: 40px;
  height: auto;
}

.banner-text {
  margin-top: 20px;
}

.banner-head-text {
  font-size: 2rem;
  font-weight: bold;
}

.banner-subhead-text {
  font-size: 25px;
  margin-top: 10px;
}

.banner-button {
  margin-top: 20px;
}

.index-page-banner-mouse-icon {
  width: 30px;
  height: auto;
}

.index-page-services-section {
  background-color: var(--background-light-mode);
  &.dark {
    background-color: var(--dark-mode-dark-text) !important;
  }
  &.ligth {
    background-color: var(--background-light-mode);
  }
}
.page-width {
  padding: 20px 20px;
  margin: 0 auto;
  background-color: var(--background-light-mode);

  &.dark {
    background-color: var(--dark-mode-dark-text) !important;
  }
  &.ligth {
    background-color: var(--background-light-mode);
  }
}
.sd-text {
  color: var(--dark-mode-dark-text);
  &.dark {
    color: var(--background-light-mode);
  }
  &.ligth {
    color: var(--dark-mode-dark-text) !important;
  }
}
.services-footer {
  background-color: var(--background-light-mode);
  &.dark {
    background-color: var(--dark-mode-dark-text) !important;
  }
  &.ligth {
    background-color: var(--background-light-mode);
  }
  .svf-text {
    color: var(--dark-mode-dark-text);
    &.dark {
      color: var(--background-light-mode);
    }
    &.ligth {
      color: var(--dark-mode-dark-text) !important;
    }
  }
}

.portfolio-cover {
  background-color: var(--background-light-mode);
  &.dark {
    background-color: var(--dark-mode-dark-text) !important;
  }
  &.ligth {
    background-color: var(--background-light-mode);
  }
  .pc-footer-text {
    color: var(--dark-mode-dark-text);
    &.dark {
      color: var(--background-light-mode);
    }
    &.ligth {
      color: var(--dark-mode-dark-text) !important;
    }
  }
}




.index-page-banner-container {
  padding: 70px 30px;
}

.banner-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0;
}

.banner-text p button {
  width: 140px;
  height: 50px;
  border-radius: 25px;
  border: none;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.banner-text p {
  padding: 10px;
  margin: 0;
}

.banner-head-text {
  font-weight: 500;
  font-size: 45px;
  text-align: center;
  letter-spacing: 3px;
  width: 80%;
  line-height: 54px;
  font-family: "graffit";
  color: #000000;
}

.index-page-banner-experience-span {
  color: #e1b023;
}



.banner-subhead-text {
  font-weight: 200;
  text-align: center;
  font-size: 35px;
  width: 50%;
  margin-bottom: 0rem;
  font-family: "Raino sans";

  &.dark {
    color: var(--light-mode-white-text);
  }

  &.light {
    color: var(--dark-mode-dark-text);
  }
}
.banner-subhead-text-two{
  font-size: 25px;
  width: 55%;
  font-family: "Raino sans";

  &.dark {
    color: var(--light-mode-white-text);
  }

  &.light {
    color: var(--dark-mode-dark-text);
  }
}
.banner-button {
  display: flex;
  gap: 20px;
}

.bn-one {
  background: #002300;
  font-family: "Raino sans";
  color: white;
}

.bn-one span {
  font-size: 14px;
  font-weight: 700;
}

.bn-two {
  margin-bottom: 2rem;
}

.bn-two span {
  font-size: 16px;
  font-weight: 600;
  color: #404040;
}

.banner-trust {
  padding-top: 20px;
}
@media screen and (min-width: 350px) and (max-width: 600px){


  .index-page-banner-container {
    padding: 20px 10px;
  }

  .banner-text {
    width: 100%;

  }
  .banner-text p {
    padding: 5px;
    margin: 0;
  }


  .index-page-banner-experience-span {
    color: #e1b023;
  }



  .banner-subhead-text {
    font-weight: 200;
    text-align: center;
    font-size: 20px;
    width: 80%;
    &.dark {
      color: var(--light-mode-white-text);
    }

    &.light {
      color: var(--dark-mode-dark-text);
    }
  }
  .banner-subhead-text-two{
    font-size: 10px;
    width: 85%;
    font-weight: 300;
    font-family: "Raino sans";

    &.dark {
      color: var(--light-mode-white-text);
    }

    &.light {
      color: var(--dark-mode-dark-text);
    }
  }
  .banner-button {
    display: flex;
    gap: 20px;
  }
  .banner-text p button {
    width: 90px;
    height: 30px;
    border-radius: 13px;
    border: none;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .bn-one {
    background: #002300;
    font-family: "Raino sans";
    color: white;
  }

  .bn-one span {
    font-size: 10px;
    font-weight: 700;
  }

  .index-page-banner-container img {
    margin-top: 10px;
    width: 30px;
    height: auto;
  }
}