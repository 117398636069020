/*


        Blog Page styling


 */
.blog-page-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 70px 0;
  background: #f7fff5;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
      margin: 15px 0;
      color: #252b42;
      text-align: center;
      font-family: "Montserrat";
      font-size: 48px;
      font-style: normal;
      font-weight: 800;
    }
    p {
      color: #737373;
      text-align: center;
      font-family: "Montserrat";
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }
}
.blog-page-blogs {
  .bpb-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 50px;
    gap: 20px;
    .bpb-box-div {
      width: 30%;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      box-shadow: 1px 1px 5px rgba(215, 215, 208, 0.74);
      align-items: center;
      .bpb-box-preview-image {
        width: 280px;
        height: 160px;
        flex-shrink: 0;
        background: red;

        img {
          width: 100%;
          height: 100%;
        }
      }
      div {
        padding: 10px;
        .bpb-time-stamp {
          color: #bdbdbd;
          font-family: "Montserrat";
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          margin: 0;
        }
        h5 {
          color: #191919;
          font-family: "Raleway";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          margin: 0;
          text-transform: uppercase;
        }
        .bpb-preview-text {
          color: #858585;
          font-family: "Montserrat", "sans-serif";
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 25px;
          height: 120px;
        }
        a {
          text-decoration: none;
          color: #f3c720;
          font-family: "Montserrat", "sans-serif";
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
        }
      }
    }
  }
}



@media screen and (min-width: 768px) {
  .blog-page-blogs {
    .bpb-box-div {
      width: calc(50% - 40px);
      margin: 20px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .blog-page-blogs {
    .bpb-box-div {
      width: calc(33.33% - 40px);
    }
  }
}
@media screen and (min-width: 350px) and (max-width: 600px) {
  .blog-page-blogs .bpb-box .bpb-box-div {
    width: auto;
  }
}