/*


About Page Styling





 */

.about-page-header {
  background: #f7fff5;
  padding: 50px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-page-header-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-page-header-div h1 {
  color: #191919;
  text-align: center;
  font-family: "Montserrat";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.093px;
  margin: 20px;
}
.about-page-header-div p {
  color: #414141;
  font-family: "DM Sans", "sans-serif";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.08px;
  margin: 0;
  text-align: center;
  width: 50%;
}

.about-page-innovation-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 150px;
}
.about-page-innovation-section-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px;
}
.about-page-innovation-section-div-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}
.about-page-innovation-section-div-left h5 {
  color: #252b42;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.1px;
  margin: 15px;
}
.about-page-innovation-section-div-left p {
  color: #737373;

  font-family: "Montserrat";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.about-page-innovation-section-div-right {
  height: 299.409px;
}
.about-page-innovation-section-div-right img {
  height: 100%;
  align-self: stretch;
}
.about-page-partners-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about-page-partners-section-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
.about-page-partners-section-div header {
  max-width: 35%;
}
.about-page-partners-section-div header h3 {
  color: #252b42;
  text-align: center;

  font-family: "Montserrat";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin: 15px;
  letter-spacing: 0.2px;
}
.about-page-partners-section-div header p {
  color: #737373;
  text-align: center;
  font-family: "Montserrat";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.2px;
}
.about-page-partners-section-div main {
}

.about-page-meet-the-team-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 100px;
  .about-page-meet-the-team-section-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .about-page-meet-the-team-section-header {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 30px;
    }
    .about-page-meet-the-team-section-images {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .about-page-meet-the-team-section-executive-images-section {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .about-page-meet-the-team-section-executive-images {
          width: 80%;
          height: 70%;
          img {
            height: 100%;
            width: 100%;
          }
        }
        .about-page-meet-the-team-section-executive-images-positions {
          display: flex;
          flex-direction: column;
          align-items: center;
          h2 {
            margin: 5px;
          }
          p {
            color: #d9d9d9;
          }
        }
      }
    }
  }
}


.about-page-work-with-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 100px;
  .about-page-work-with-us-section-div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .about-page-work-with-us-section-details {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    .about-page-work-with-us-section-details-div {
      width: 30%;
      .about-page-work-with-us-section-details-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
      }
      .about-page-work-with-us-section-details-div-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
        }
      }
    }
  }
}

/* Responsive Design */
@media screen and (min-width: 350px) and (max-width: 600px) {
  .individual-article-opened-header-box {
    width: 100%;
    padding: 0 20px;
  }

  .about-page-header-div h2 {
    font-size: 0.9rem;
  }



  .about-page-meet-the-team-section {
   ;
    padding: 30px 40px;
    .about-page-meet-the-team-section-div {
      .about-page-meet-the-team-section-header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
      }
      .about-page-meet-the-team-section-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        .about-page-meet-the-team-section-executive-images-section {
          width: 40%;
          .about-page-meet-the-team-section-executive-images {
            width: 80%;
            height: 70%;
            img {
              height: 100%;
              width: 100%;
            }
          }
          .about-page-meet-the-team-section-executive-images-positions {
            display: flex;
            flex-direction: column;
            align-items: center;
            h2 {
              margin: 5px;
              font-size: 12px;
            }
            p {
              color: #d9d9d9;
              font-size: 9px;
            }
          }
        }
      }
    }
  }


  .about-page-work-with-us-section {
    padding: 10px 40px;
    .about-page-work-with-us-section-div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .about-page-work-with-us-section-details {
      display: flex;
      gap: 5px;
      flex-direction: column;
      justify-content: space-between;
      .about-page-work-with-us-section-details-div {
        width: 100%;
        .about-page-work-with-us-section-details-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
          width: 100%;

          img{
            width: 100px;
            height: 100%;
          }
        }
        .about-page-work-with-us-section-details-div-text {
          display: flex;
          flex-direction: column;
          align-items: center;
          p {
            text-align: center;
            font-size: 10px;
          }
        }
      }
    }
    .about-page-work-with-us-section-header{
      h2{
        font-size: 14px;
      }
    }
  }
  .about-page-partners-section-div header{
    max-width: 70%;
    p{
      font-size: 10px;
      line-height: 20px;
    }
  }
}
