/*
        blog-component styles
*/

.blog-navbar {
  padding: 40px 0;
}
.index-page-blog-component {
  background-color: var(--background-light-mode);
  overflow: hidden;
}
.blog-header {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blog-header h1 {
  font-size: 70px;
  margin: 30px 0;
}
.blog-header h3 {
  font-size: 25px;
  max-width: 60%;
  font-weight: 300;
  margin: 10px;
  text-align: center;
  letter-spacing: 2px;
  font-family: "DM Sans";
}

.index-page-blog-component-div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.blog-post {
  display: flex;
  justify-content: space-between;
  background-color: var(--blog-light-background);
  padding: 20px 25px;
  border-radius: 32px;
}

.blog-post-image {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.blog-post-image p {
  width: 100%;
  height: 100%;
}

.blog-post-image p img {
  width: 100%;
  border-radius: 15px;
  height: 100%;
}

.blog-details {
  width: 45%;
  padding: 40px;
}

.blog-details-header {
  display: flex;
  gap: 15px;
  margin: 0;
}

.blog-details-header p {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  gap: 5px;
  margin: 0;
  color: var(--light-mode-white-text);
}

.blog-icons {
  padding-top: 5px;
  margin-top: auto;
  color: var(--light-mode-white-text);
}

.blog-topic {
  font-size: 19px;
  font-weight: 500;
  margin: 0;

  color: var(--light-mode-white-text);
}

.blog-light {
  color: var(--light-mode-white-text);
  font-weight: 300;
}

.blog-content {
  font-weight: 300;
  color: #404040;
  font-size: 14px;
  color: var(--light-mode-white-text);
}

.blog-footer {
  display: flex;
  gap: 20px;
  color: var(--light-mode-white-text);
}

.blog-footer-details {
  display: flex;
  gap: 5px;
  height: 3rem;
  align-items: center;
}

.blog-footer-button {
  width: 35%;
  color: var(--light-mode-white-text);
}

.blog-footer-button button {
  width: 100%;
  padding: 10px 10px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  gap: 15px;
  color: var(--light-mode-white-text);
  background: #0b63e5;
}

.index-page-blog-component-blog-read-more-button {
  color: var(--light-mode-white-text);
  text-decoration: none;
}

.blog-footer-details-image {
  width: 30px;
}
.blog-footer-details-image img {
  width: 100%;
}

.blog-footer-line {
  margin: 3px;
  padding: 2px;
  border-right: 2px solid #404040;
}

.blog-footer-details-name {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bfd-name {
  font-size: 14px;
}

.bfd-duration {
  font-size: 10px;
  font-weight: 300;
  padding: 5px;
  color: #404040;
}

@media screen and (min-width: 350px) and (max-width: 600px) {
  .blog-header{
    padding: 10px 0;
  }
  .blog-header h1 {
    font-size: 30px;
    margin: 5px;
  }

  .blog-header h3 {
    font-size: 15px;
    letter-spacing: 1px;
    max-width: 80%;
  }

  .blog-post-image p img {
    width: 100%;
    height: 300px;
  }
  .blog-details-header{
    margin-right: 2rem;
    justify-content: space-between;
  }
  .blog-post {
    flex-direction: column;
    background: none;
    padding: 10px;
  }
  .blog-post-image {
    width: 100%;
    padding: 0;
  }
  .blog-details {
    width: 100%;
    padding: 0;

  }

  .blog-icons{
    font-size: 25px;
    color: var(--light-mode-dark-text);

  }
  .blg-det{
    color: var(--light-mode-dark-text);
    font-size: 10px;
  }
  .blog-light{
    color: var(--light-mode-dark-text);

  }
  .blog-topic{
    font-size: 1rem;
    margin-right: 1.4rem;
    color: var(--light-mode-dark-text);

  }
  .blog-content{
    color: #42526B;
    font-size: 10px;
  }
  .blog-footer{
    color: var(--light-mode-dark-text);
    .bfd-name{
      font-size: 10px;
    }
  }
  .index-page-blog-component-blog-read-more-button{
    font-size: 12px;
  }
  .blog-footer-button button{
    font-size: 12px;
    padding: 10px 5px;
  }
}
