/*
        input-dropdown styles
*/

.dropdown{
  width:47%;
  display: flex;align-items: center;flex-direction: column;
  position: relative;
}
.dropdown-content{
  width: 100%;
  position: absolute;
  background: #E6E8EC ;
  top:50px;
}
.dropdown-btn{
  width: 90%;
  height: 100%;
  font-size: 14px;
  color: #404040;
  border: 1px solid #E6E8EC;
  border-radius: 5px;
  padding: 16px 10px;
}
.dropdown-items{
}
.dropdown-content ul{
  width: 100%;
  padding:0;
  margin: 0;
}
.dropdown-items{
  list-style: none;
  padding:5px ;
  margin-top: 10px;
  margin-left: auto;
  font-size: 12px;
}
.dropdown-items:hover{
  background: #b9b7b7;
  transition: ease-in-out .5s;
  cursor: pointer;
}
@media screen and (min-width: 350px) and (max-width: 600px) {
  .dropdown{
    width: 100%;
    display: block;
  }
  .dropdown-btn{
    width: 94%;
    padding: 11px;
    font-size: 9px;
  }
}