
/*
        navbar-styles
*/
.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.light-navbar {
}

.nav-con {
  display: flex;
  background: #fafafa;
  justify-content: space-between;
  align-items: center;
  border-radius: 40px;
  padding: 5px 10px;
  box-shadow: 0px 18px 40px 0px rgba(255, 254, 254, 0.1);
}

.light-nav-con {
  box-shadow: 0px 18px 40px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  background: #ffffff;
  justify-content: space-between;
  align-items: center;
  border-radius: 40px;
  padding: 5px 10px;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-right: 30px;
}

.logo img {
  width: 100%;
  height: 20px;
}

.nav-list-con {
  display: flex;
  list-style: none;
  justify-content: center;
  gap: 40px;
}

.nav-list {
  text-decoration: none;
  color: #000000;
  font-weight: 300;
}

.nav-list-con ul {
  padding: 3px;
}

.nav-list-con li {
  position: relative;
  cursor: pointer;
  width: fit-content;
  list-style: none;
  font-size: 17px;
  font-family: "Instrument Sans", sans-serif;
}

.nav-list-con li::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -4px;
  height: 2px;
  background: currentColor;
  transform: scaleX(0);
  transition: transform 200ms ease-in-out;
}

.nav-list-con li:hover::after {
  color: #404040;
  transform: scaleX(1);
}

.nav-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-left: 70px;
}

.nvp {
  margin: 0.5rem;
}

.nav-buttons p button {
  background: #003700;
  cursor: pointer;
  border-radius: 35px;
  padding: 15px 25px;
  font-size: 17px;
  border: none;
  font-family: "Instrument Sans", sans-serif;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.search-icon {
  font-size: 28px;
  cursor: pointer;
  font-weight: 700;
  color: #bb7401;
}

.nvb-arrow {
  text-align: center;
}
.banner-section {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 100%;
  background-color: var(--background-light-mode);
  transition-duration: 1000ms;
}

.nav-placement {
  padding: 10px;
}
@media screen and (min-width: 350px) and (max-width: 600px){
  .navbar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .light-navbar {
  }

  .nav-con {
    display: flex;
    background: #fafafa;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    padding: 5px 10px;
    box-shadow: 0px 18px 40px 0px rgba(255, 254, 254, 0.1);
  }

  .light-nav-con {
    box-shadow: 0px 18px 40px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    background: #ffffff;
    justify-content: space-between;
    align-items: center;
    border-radius: 40px;
    padding: 5px 10px;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    margin-right: 5px;
  }

  .logo img {
    width: 100%;
    height: 10px;
  }

  .nav-list-con {
    display: flex;
    list-style: none;
    justify-content: center;
    gap: 10px;
  }

  .nav-list {
    text-decoration: none;
    color: #000000;
    font-weight: 300;
  }

  .nav-list-con ul {
    padding: 3px;
  }

  .nav-list-con li {
    position: relative;
    cursor: pointer;
    width: fit-content;
    list-style: none;
    font-size: 7px;
    font-family: "Instrument Sans", sans-serif;
  }

  .nav-list-con li::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -4px;
    height: 2px;
    background: currentColor;
    transform: scaleX(0);
    transition: transform 200ms ease-in-out;
  }

  .nav-list-con li:hover::after {
    color: #404040;
    transform: scaleX(1);
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-left: 5px;
  }

  .nvp {
    margin: 0.5rem;
  }

  .nav-buttons p button {
    background: #003700;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px 5px;
    font-size: 5px;
    border: none;
    font-family: "Instrument Sans", sans-serif;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  .search-icon {
    font-size: 28px;
    cursor: pointer;
    font-weight: 700;
    color: #bb7401;
  }

  .nvb-arrow {
    text-align: center;
  }
  .banner-section {
    position: relative;
  }

  .banner-image {
    width: 100%;
    height: 100%;
    background-color: var(--background-light-mode);
    transition-duration: 1000ms;
  }

  .nav-placement {
    padding: 10px;
  }
}